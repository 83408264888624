<script setup lang="ts">
import { FetchError } from "ofetch";
import { GoogleMap, AdvancedMarker } from "vue3-google-map";
import { useEventListener } from "@vueuse/core";
import { useForm, Field } from "vee-validate";
import { toTypedSchema } from "@vee-validate/zod";
import { loginSchema } from "@/lib/validation/auth.schema";
import { navigateToDashboard } from "@/lib/utils";
import { useUser } from "@/composables/useUser";
import type { User } from "@/server/db/schema";
import { UserRole } from "@/lib/models/user.model";

useHead({
  title: "Login",
});

const config = useRuntimeConfig();
const toast = useToast();
const loading = ref(false);
const mapRef = ref(null);

const mapOptions = computed(() => ({
  center: { lat: 0, lng: 0 },
  zoom: 2,
  mapTypeId: "hybrid",
  disableDefaultUI: true,
  gestureHandling: "none" as const,
  keyboardShortcuts: false,
  clickableIcons: false,
  minZoom: 2,
  maxZoom: 18,
  restriction: {
    latLngBounds: {
      north: 85,
      south: -85,
      west: -180,
      east: 180,
    },
    strictBounds: true,
  },
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
  scaleControl: false,
  rotateControl: false,
}));

// Initialize form with Vee-Validate + Zod
const { handleSubmit, setFieldValue } = useForm({
  validationSchema: toTypedSchema(loginSchema),
  initialValues: {
    email: "",
    password: "",
  },
  validateOnMount: false,
});

// Add this function to transform input to lowercase
const handleEmailInput = (event: Event) => {
  const input = event.target as HTMLInputElement;
  setFieldValue("email", input.value.toLowerCase());
};

const route = useRoute();
const redirectPath = computed(() => {
  const redirect = route.query.redirect as string;

  // Validate the redirect URL
  if (redirect) {
    try {
      // Decode the URL first
      const decodedUrl = decodeURIComponent(redirect);

      // Basic validation to ensure it's an internal URL
      if (
        decodedUrl.startsWith("/") &&
        !["/login", "/register"].includes(decodedUrl.split("?")[0])
      ) {
        return decodedUrl; // Return the full URL including query params
      }
    } catch (e) {
      console.error("Invalid redirect URL:", e);
    }
  }

  return "/";
});

const onSubmit = handleSubmit(async (formValues) => {
  loading.value = true;

  const formData = new FormData();
  formData.append("email", formValues.email.trim().toLowerCase());
  formData.append("password", formValues.password.trim());

  try {
    const response = await $fetch<{ user: User }>("/api/auth/password/signin", {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });

    // Update user state
    const user = useUser();
    user.value = response.user;

    // Validate the user role before navigation
    if (
      !response.user ||
      !Object.values(UserRole).includes(response.user.role)
    ) {
      console.error("Invalid user role:", response.user?.role);
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Invalid user role. Please contact support.",
        life: 3000,
      });
      return;
    }

    // After successful authentication, handle redirect
    const targetPath =
      redirectPath.value === "/"
        ? navigateToDashboard(response.user.role)
        : redirectPath.value;

    // Prevent unnecessary navigation to current path
    if (targetPath !== window.location.pathname) {
      await navigateTo(targetPath);
    }
  } catch (error) {
    console.error("Login error:", error);

    if (error instanceof FetchError) {
      toast.add({
        severity: "error",
        summary: "Login Failed",
        detail: error.data?.message || "Invalid username or password",
        life: 3000,
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "An unexpected error occurred",
        life: 3000,
      });
    }
  } finally {
    loading.value = false;
  }
});

// Map related code...
const { data: locations, status: fetchStatus } = await useFetch(
  "/api/public/locations",
);
const filteredLocations = computed(
  () =>
    locations.value
      ?.filter((location) => {
        const latitude = parseFloat(location.latitude ?? "");
        const longitude = parseFloat(location.longitude ?? "");
        return !Number.isNaN(latitude) && !Number.isNaN(longitude);
      })
      .map((location) => ({
        lat: Number(location.latitude),
        lng: Number(location.longitude),
      })) ?? [],
);

const tilesLoaded = ref(false);
const isLoading = computed(
  () =>
    ["pending", "idle"].includes(toValue(fetchStatus)) || !tilesLoaded.value,
);

const fitBounds = () => {
  if (!mapRef.value || !filteredLocations.value.length) return;
  const mapRefVal: any = mapRef.value;
  if (!mapRefVal.ready) return;
  const gmap = mapRefVal.map;
  const api = mapRefVal.api;
  const bounds = new api.LatLngBounds();
  for (const location of filteredLocations.value) {
    bounds.extend(location);
  }
  gmap.fitBounds(bounds);
};

watch(
  () => (mapRef.value as any)?.ready,
  (ready) => {
    if (ready) fitBounds();
  },
);

useEventListener(window, "resize", fitBounds);

const onTilesLoaded = () => {
  tilesLoaded.value = true;
};

// Add a computed property for marker options
const getMarkerOptions = (location: { lat: number; lng: number }) => ({
  position: location,
  title: "Location Marker",
});
</script>

<template>
  <div class="min-h-screen flex flex-col md:flex-row">
    <div
      class="flex-1 md:w-1/4 md:min-w-[500px] flex flex-col p-2 bg-custom-blue"
    >
      <Toast />
      <div class="flex logo">
        <span>TAKE</span>
        <span class="text-10">10</span>
        <span>MEDIA</span>
      </div>
      <div class="flex flex-col justify-center items-center h-full">
        <Card class="w-[350px] bg-white/50 backdrop-blur-md shadow-lg">
          <template #title>
            <div
              class="flex justify-center items-center text-2xl font-semibold mb-4"
            >
              Sign In
            </div>
          </template>
          <template #content>
            <form class="flex flex-col gap-4" @submit.prevent="onSubmit">
              <div class="field">
                <Field v-slot="{ field, errorMessage, meta }" name="email">
                  <InputText
                    v-bind="field"
                    :class="{ 'p-invalid': errorMessage && meta.touched }"
                    class="w-full"
                    placeholder="Email"
                    autocomplete="username"
                    @input="handleEmailInput"
                  />
                  <small v-if="errorMessage && meta.touched" class="p-error">
                    {{ errorMessage }}
                  </small>
                </Field>
              </div>
              <div class="field">
                <Field v-slot="{ field, errorMessage, meta }" name="password">
                  <Password
                    v-bind="field"
                    :class="{ 'p-invalid': errorMessage && meta.touched }"
                    toggle-mask
                    :feedback="false"
                    prompt-label="Enter your password"
                    class="password"
                    placeholder="Password"
                    :input-props="{
                      autocomplete: 'current-password',
                    }"
                  />
                  <small v-if="errorMessage && meta.touched" class="p-error">
                    {{ errorMessage }}
                  </small>
                  <small class="text-gray-500 mt-1 block"
                    >Password is case sensitive</small
                  >
                </Field>
              </div>
              <div class="field text-right">
                <span class="text-base">
                  <NuxtLink to="/forgotpassword" class="underline">
                    Forgot your password?
                  </NuxtLink>
                </span>
              </div>
              <div class="field mt-4 mb-4">
                <Button
                  type="submit"
                  label="Sign In"
                  :loading="loading"
                  class="bg-button text-white hover:bg-button active:bg-button w-full"
                />
              </div>
            </form>
          </template>
          <template #footer>
            <div class="text-center">
              <span class="text-base">
                <NuxtLink to="/register" class="underline">
                  Not registered?
                  <span class="font-semibold">Create an account</span>
                </NuxtLink>
              </span>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div class="h-[40vh] md:w-3/4 md:h-screen relative">
      <div class="h-full" :class="{ invisible: isLoading }">
        <GoogleMap
          v-if="filteredLocations.length > 0"
          ref="mapRef"
          :api-key="config.public.googleMapsApiKey"
          style="width: 100%; height: 100%"
          :map-id="config.public.googleMapsMapId"
          v-bind="mapOptions"
          @tilesloaded="onTilesLoaded"
        >
          <AdvancedMarker
            v-for="(location, i) in filteredLocations"
            :key="i"
            :options="getMarkerOptions(location)"
          />
        </GoogleMap>
        <div v-else class="flex items-center justify-center h-full">
          <p class="text-2xl">No locations found.</p>
        </div>
      </div>

      <div
        v-if="isLoading"
        class="absolute inset-0 flex items-center justify-center bg-gray-900"
      >
        <ProgressSpinner
          stroke-width="4"
          animation-duration=".5s"
          aria-label="Loading Locations"
        />
      </div>
    </div>
  </div>
</template>

<style>
.bg-custom-blue {
  background: linear-gradient(to bottom right, white, #a3b1d0);
}

.p-inputtext {
  width: 100% !important;
}

.p-password {
  width: 100% !important;
}

.p-password-input {
  width: 100% !important;
}

.logo {
  font-weight: 700;
  font-size: 24px;
  font-family: "Roboto Mono", sans-serif;
}

.text-10 {
  color: #18bc84;
}

.bg-button {
  background: #18bc84;
}

/* Validation styles */
.p-invalid {
  border-color: #ff5757 !important;
}

.p-invalid::placeholder {
  color: #6c757d !important;
}

.p-error {
  color: #ff5757;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: block;
}

/* Make sure Password component shows validation */
:deep(.p-password-input.p-invalid) {
  border-color: #ff5757 !important;
}

:deep(.p-password-input.p-invalid::placeholder) {
  color: #6c757d !important;
}

:deep(.p-password.p-invalid > .p-password-input) {
  border-color: #ff5757 !important;
}
</style>
